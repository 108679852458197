.feature-card-feature-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
}
.feature-card-icon {
  fill: #037AB3;
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  margin-right: var(--dl-space-space-oneandhalfunits);
}
.feature-card-container {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.feature-card-text {
  font-size: 2rem;
  font-family: "Raleway";
  font-weight: 600;
  line-height: 28px;
}
.feature-card-text1 {
  color: var(--dl-color-gray-800);
  line-height: 1.6;
}
@media(max-width: 767px) {
  .feature-card-feature-card {
    flex-direction: row;
  }
  .feature-card-container {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .feature-card-container {
    flex-direction: column;
  }
}
