.inicio-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.inicio-header {
  z-index: 1000;
}
.inicio-navbar-interactive {
  width: 90%;
  display: flex;
  position: sticky;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.inicio-link {
  display: contents;
}
.inicio-image {
  width: 126px;
  height: 89px;
  font-size: 2em;
  font-family: "Lato";
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
}
.inicio-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.inicio-links {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.inicio-nav1 {
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
}
.inicio-nav1:hover {
  color: #EA0A48;
}
.inicio-nav2 {
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
  margin-left: var(--dl-space-space-twounits);
  text-transform: uppercase;
  text-decoration: none;
}
.inicio-nav2:hover {
  color: #EA0A48;
}
.inicio-nav3 {
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
  margin-left: var(--dl-space-space-twounits);
  text-transform: uppercase;
}
.inicio-nav3:hover {
  color: #EA0A48;
}
.inicio-nav4 {
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
  margin-left: var(--dl-space-space-twounits);
  text-transform: uppercase;
}
.inicio-nav4:hover {
  color: #EA0A48;
}
.inicio-buttons {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.inicio-login {
  color: rgb(234, 10, 72);
  font-style: normal;
  transition: 0.3s;
  font-weight: 700;
  border-color: #ea0a48;
  border-width: 1px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: 1.5rem;
  border-radius: 45px;
  padding-right: 1.5rem;
  text-transform: uppercase;
}
.inicio-login:hover {
  color: var(--dl-color-gray-white);
  background-color: #ea0a48;
}
.inicio-register {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  font-weight: bold;
  border-color: #037ab3;
  padding-left: 1.5rem;
  border-radius: 45px;
  padding-right: 1.5rem;
  text-transform: uppercase;
  background-color: rgb(3, 122, 179);
}
.inicio-register:hover {
  border-color: #0E8DCB;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background-color: #0e8dcb;
}
.inicio-burger-menu {
  display: none;
}
.inicio-icon {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.inicio-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  border-radius: 50px;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.inicio-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.inicio-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.inicio-logo {
  font-size: 2em;
  font-weight: bold;
}
.inicio-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.inicio-icon02 {
  fill: var(--dl-color-gray-900);
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.inicio-links1 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.inicio-nav11 {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-unit);
}
.inicio-nav21 {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-unit);
}
.inicio-nav31 {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-unit);
}
.inicio-nav41 {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-unit);
}
.inicio-nav5 {
  color: var(--dl-color-gray-black);
  margin-bottom: var(--dl-space-space-unit);
}
.inicio-buttons1 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.inicio-login1 {
  border-color: var(--dl-color-primary1-blue100);
  border-width: 1px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: 1.5rem;
  border-radius: 30px;
  padding-right: 1.5rem;
}
.inicio-register1 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  border-color: var(--dl-color-primary1-blue100);
  border-width: 1px;
  padding-left: 1.5rem;
  border-radius: 30px;
  padding-right: 1.5rem;
  background-color: var(--dl-color-primary1-blue100);
}
.inicio-register1:hover {
  border-color: #849492ff;
  background-color: #849492ff;
}
.inicio-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.inicio-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.inicio-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.inicio-hero {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  background-size: cover;
  justify-content: center;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0.00%,rgba(0, 0, 0, 0.5) 100.00%),url("/external/portada-dulces-alicia-optimizado-1500w.jpg");
  background-position: top left,center;
}
.inicio-hero1 {
  width: 100%;
  height: 820px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  min-height: auto;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.inicio-container01 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.inicio-hero-heading {
  color: var(--dl-color-gray-white);
  max-width: 800px;
  text-align: center;
  font-family: "Raleway";
  text-transform: uppercase;
}
.inicio-hero-sub-heading {
  color: var(--dl-color-gray-white);
  font-size: 28px;
  text-align: center;
  line-height: 1.6;
}
.inicio-btn-group {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.inicio-hero-button1 {
  color: var(--dl-color-gray-white);
  font-size: 18px;
  transition: 0.3s;
  font-weight: bold;
  padding-top: 1.5rem;
  border-color: var(--dl-color-primary1-blue100);
  border-width: 0px;
  padding-left: 3rem;
  border-radius: 45px;
  padding-right: 3rem;
  padding-bottom: 1.5rem;
  text-transform: uppercase;
  background-color: rgb(3, 122, 179);
}
.inicio-hero-button1:hover {
  color: var(--dl-color-gray-white);
  border-color: #078fd0;
  background-color: #078fd0;
}
.inicio-hero-button2 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  font-weight: bold;
  padding-top: var(--dl-space-space-unit);
  border-color: transparent;
  border-width: 10px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 80px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-transform: uppercase;
  background-color: transparent;
}
.inicio-hero-button2:hover {
  color: #EA0A48;
  border-color: var(--dl-color-gray-white);
  border-width: 10px;
  border-radius: 80px;
  background-color: #ffffff;
  border-left-width: 10px;
}
.inicio-gallery {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #efefef;
}
.inicio-gallery1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
}
.inicio-gallery-heading {
  text-align: center;
  font-family: "Raleway";
  line-height: 1.6;
}
.inicio-gallery-sub-heading {
  color: rgb(153, 153, 153);
  text-align: center;
  line-height: 1.6;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
}
.inicio-container02 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-unit);
  margin-top: var(--dl-space-space-twounits);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.inicio-details {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inicio-details1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}
.inicio-container03 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.inicio-text {
  color: #ea0a48;
  text-align: left;
  font-family: "Raleway";
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.inicio-details-heading {
  width: 80%;
  text-align: left;
  font-family: "Raleway";
  line-height: 1.6;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.inicio-details-sub-heading {
  color: var(--dl-color-gray-800);
  font-size: 18px;
  text-align: left;
  line-height: 1.6;
  margin-bottom: var(--dl-space-space-twounits);
}
.inicio-details-image {
  width: 400px;
  height: 400px;
  object-fit: cover;
  transition: 0.3s;
  margin-left: var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-round);
}
.inicio-details-image:hover {
  width: 420px;
  height: 420px;
}
.inicio-features {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inicio-features-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-secondary-grey400);
}
.inicio-features1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: flex-start;
}
.inicio-container04 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.inicio-text03 {
  color: #ea0a48;
  font-family: "Raleway";
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.inicio-features-heading {
  font-family: "Raleway";
  line-height: 1.6;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.inicio-features-sub-heading {
  color: var(--dl-color-gray-800);
  font-size: 18px;
  text-align: center;
  line-height: 1.6;
}
.inicio-container05 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  grid-template-columns: 1fr 1fr;
}
.inicio-banner {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.inicio-banner1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: var(--dl-size-size-maxwidth);
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: flex-start;
}
.inicio-banner-heading {
  color: var(--dl-color-gray-white);
  text-align: center;
  font-family: "Raleway";
  line-height: 1.6;
}
.inicio-banner-sub-heading {
  color: var(--dl-color-gray-white);
  max-width: var(--dl-size-size-maxwidth);
  text-align: center;
  line-height: 1.6;
}
.inicio-banner-button {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  font-weight: 700;
  padding-top: 1.5rem;
  border-width: 0px;
  padding-left: 3rem;
  border-radius: 45px;
  padding-right: 3rem;
  padding-bottom: 1.5rem;
  text-transform: uppercase;
  background-color: rgb(3, 122, 179);
}
.inicio-banner-button:hover {
  border-color: rgba(41, 20, 119, 0.9);
  background-color: #0990d0;
}
.inicio-faq {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inicio-faq-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.inicio-faq1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fourunits);
}
.inicio-container06 {
  display: flex;
  max-width: 35%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.inicio-text06 {
  color: #037ab3;
  font-family: "Raleway";
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.inicio-text09 {
  font-family: "Raleway";
  line-height: 1;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.inicio-text10 {
  color: var(--dl-color-gray-800);
  font-size: 18px;
  text-align: left;
  line-height: 1.6;
}
.inicio-container07 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.inicio-footer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.inicio-footer1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}
.inicio-container08 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.inicio-logo1 {
  color: var(--dl-color-gray-white);
  font-size: 2em;
  font-weight: bold;
  text-decoration: none;
}
.inicio-nav1 {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.inicio-nav12 {
  color: var(--dl-color-secondary-gray500);
  margin-left: 0px;
  text-transform: uppercase;
}
.inicio-nav22 {
  color: var(--dl-color-secondary-gray500);
  margin-left: var(--dl-space-space-unit);
  text-transform: uppercase;
}
.inicio-nav32 {
  color: var(--dl-color-secondary-gray500);
  margin-left: var(--dl-space-space-unit);
  text-transform: uppercase;
}
.inicio-nav42 {
  color: var(--dl-color-secondary-gray500);
  margin-left: var(--dl-space-space-unit);
  text-transform: uppercase;
}
.inicio-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-800);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.inicio-container09 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.inicio-text11 {
  color: var(--dl-color-secondary-gray500);
}
.inicio-icon-group1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.inicio-link1 {
  display: contents;
}
.inicio-icon10 {
  fill: var(--dl-color-secondary-gray500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.inicio-link2 {
  display: contents;
}
.inicio-icon12 {
  fill: var(--dl-color-secondary-gray500);
  width: 24px;
  height: 24px;
  text-decoration: none;
}
@media(max-width: 991px) {
  .inicio-hero1 {
    flex-direction: column;
  }
  .inicio-container01 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .inicio-hero-heading {
    text-align: center;
  }
  .inicio-hero-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .inicio-gallery-sub-heading {
    text-align: center;
  }
  .inicio-container02 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .inicio-details-sub-heading {
    text-align: left;
    padding-right: var(--dl-space-space-threeunits);
  }
  .inicio-features-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .inicio-banner-sub-heading {
    max-width: 100%;
  }
  .inicio-text10 {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .inicio-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .inicio-desktop-menu {
    display: none;
  }
  .inicio-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .inicio-mobile-menu {
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .inicio-nav11 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .inicio-nav21 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .inicio-nav31 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .inicio-nav41 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .inicio-nav5 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .inicio-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .inicio-hero-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .inicio-gallery1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .inicio-gallery-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .inicio-container02 {
    grid-template-columns: 1fr 1fr;
  }
  .inicio-details1 {
    align-items: center;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: center;
  }
  .inicio-container03 {
    align-items: center;
    justify-content: flex-start;
  }
  .inicio-text {
    text-align: center;
  }
  .inicio-details-heading {
    text-align: center;
  }
  .inicio-details-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .inicio-details-image {
    margin-top: var(--dl-space-space-threeunits);
    margin-left: 0px;
  }
  .inicio-features1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .inicio-features-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .inicio-container05 {
    grid-template-columns: 1fr;
  }
  .inicio-banner1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .inicio-banner-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .inicio-faq1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .inicio-container06 {
    max-width: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .inicio-text10 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .inicio-footer1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .inicio-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .inicio-container09 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .inicio-text11 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .inicio-navbar-interactive {
    width: 90%;
    padding: var(--dl-space-space-unit);
  }
  .inicio-image {
    font-size: 1.5em;
  }
  .inicio-mobile-menu {
    padding: 16px;
  }
  .inicio-logo {
    font-size: 1.5em;
  }
  .inicio-icon02 {
    fill: var(--dl-color-gray-900);
  }
  .inicio-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .inicio-container01 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .inicio-btn-group {
    flex-direction: column;
  }
  .inicio-hero-button2 {
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
  }
  .inicio-gallery1 {
    padding: var(--dl-space-space-unit);
  }
  .inicio-container02 {
    grid-template-columns: 1fr;
  }
  .inicio-features1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .inicio-features-heading {
    text-align: center;
  }
  .inicio-banner1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .inicio-footer1 {
    padding: var(--dl-space-space-unit);
  }
  .inicio-logo1 {
    text-align: center;
  }
  .inicio-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .inicio-container09 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .inicio-text11 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
