.sobre-nosotros-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.sobre-nosotros-hero {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  min-height: 60vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 26.00%,rgba(0, 0, 0, 0) 55.00%),url("/external/conservas-dulces-alicia-maracaibo-1500h.jpg");
  background-repeat: repeat,no-repeat;
  background-position: top left,center;
}
.sobre-nosotros-container01 {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.sobre-nosotros-text {
  color: var(--dl-color-gray-white);
  font-size: 3rem;
  max-width: 25rem;
}
.sobre-nosotros-text01 {
  color: var(--dl-color-gray-white);
  font-size: 1.15rem;
  max-width: 60%;
  font-style: normal;
  margin-top: var(--dl-space-space-threeunits);
  text-align: justify;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-threeunits);
}
.sobre-nosotros-button {
  color: var(--dl-color-gray-white);
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-weight: 600;
  padding-top: var(--dl-space-space-unit);
  border-color: #037ab3;
  border-width: 1px;
  margin-right: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 80px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-transform: uppercase;
  background-color: #037ab3;
}
.sobre-nosotros-button:hover {
  transform: scale(1.02);
  border-color: #0987c4;
  background-color: #0987c4;
}
.sobre-nosotros-features {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
}
.sobre-nosotros-container02 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.sobre-nosotros-container03 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.sobre-nosotros-container04 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.sobre-nosotros-container05 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.sobre-nosotros-text02 {
  font-size: 3rem;
  align-self: flex-start;
  margin-bottom: var(--dl-space-space-unit);
}
.sobre-nosotros-separator {
  width: 100px;
  height: 2px;
  align-self: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-gray-500);
}
.sobre-nosotros-text03 {
  font-size: 20px;
  font-style: normal;
  text-align: justify;
  font-weight: 400;
}
.sobre-nosotros-image {
  width: 450px;
  height: 450px;
  object-fit: cover;
  flex-shrink: 0;
  margin-left: var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-round);
  object-position: right;
}
.sobre-nosotros-hero1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxwidth);
  min-height: 70vh;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: #efefef;
}
.sobre-nosotros-image1 {
  width: 25rem;
  object-fit: cover;
  border-radius: 20px;
}
.sobre-nosotros-container06 {
  display: flex;
  margin-left: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.sobre-nosotros-text08 {
  font-size: 3rem;
}
.sobre-nosotros-text09 {
  font-size: 20px;
  margin-top: var(--dl-space-space-twounits);
  text-align: justify;
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-threeunits);
}
.sobre-nosotros-hero2 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  min-height: 70vh;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-image: linear-gradient(135deg, rgba(183, 11, 58, 0.6) 53.00%,rgba(234, 10, 72, 0.8) 100.00%),url("/external/fondo-dulces-alicia-maracaibo-1500h.jpg");
}
.sobre-nosotros-container07 {
  display: flex;
  margin-right: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.sobre-nosotros-text10 {
  color: var(--dl-color-gray-white);
  font-size: 3rem;
  max-width: 450px;
}
.sobre-nosotros-text11 {
  color: var(--dl-color-gray-white);
  font-size: 22px;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 400;
  margin-bottom: var(--dl-space-space-twounits);
}
.sobre-nosotros-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.sobre-nosotros-button1 {
  color: var(--dl-color-gray-white);
  font-size: 22px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 600;
  padding-top: var(--dl-space-space-unit);
  border-color: #037ab3;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 80px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-transform: uppercase;
  background-color: rgb(3, 122, 179);
}
.sobre-nosotros-button1:hover {
  color: var(--dl-color-gray-white);
  border-color: #077FBC;
  background-color: #077fbc;
}
.sobre-nosotros-image2 {
  width: 400px;
  object-fit: cover;
  border-radius: 20px;
}
.sobre-nosotros-hero3 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  min-height: 80vh;
  align-items: center;
  padding-top: 48px;
  flex-direction: column;
  justify-content: center;
}
.sobre-nosotros-image3 {
  width: 100%;
  margin-bottom: var(--dl-space-space-twounits);
}
.sobre-nosotros-container08 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.sobre-nosotros-text12 {
  font-size: 3rem;
}
.sobre-nosotros-text13 {
  font-size: 20px;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  padding-left: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-threeunits);
}
.sobre-nosotros-hero4 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  min-height: 60vh;
  align-items: center;
  padding-top: 48px;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: linear-gradient(0deg, rgba(189, 195, 199, 0) 37.00%,rgb(255, 255, 255) 100.00%),url("/external/chocolate-dulces-alicia-maracaibo-1500h.jpg");
  background-position: top left,bottom;
}
.sobre-nosotros-container09 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.sobre-nosotros-text14 {
  font-size: 3rem;
}
.sobre-nosotros-text15 {
  font-size: 20px;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  padding-left: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-threeunits);
}
@media(max-width: 991px) {
  .sobre-nosotros-features {
    max-width: var(--dl-size-size-maxwidth);
    align-items: center;
  }
  .sobre-nosotros-container02 {
    width: 100%;
    flex-direction: column;
  }
  .sobre-nosotros-container03 {
    width: 100%;
  }
  .sobre-nosotros-text02 {
    align-self: center;
    text-align: center;
  }
  .sobre-nosotros-separator {
    align-self: center;
  }
  .sobre-nosotros-text03 {
    text-align: center;
  }
  .sobre-nosotros-image {
    width: 300px;
    height: 300px;
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
  }
  .sobre-nosotros-hero1 {
    padding: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .sobre-nosotros-image1 {
    order: 2;
  }
  .sobre-nosotros-container06 {
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .sobre-nosotros-text08 {
    text-align: center;
  }
  .sobre-nosotros-text09 {
    text-align: center;
    padding-left: var(--dl-space-space-fourunits);
  }
  .sobre-nosotros-hero2 {
    flex-direction: column;
  }
  .sobre-nosotros-container07 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .sobre-nosotros-text10 {
    text-align: center;
  }
  .sobre-nosotros-text11 {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .sobre-nosotros-hero3 {
    flex-direction: column;
  }
  .sobre-nosotros-image3 {
    width: 80%;
  }
  .sobre-nosotros-container08 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .sobre-nosotros-text12 {
    text-align: center;
  }
  .sobre-nosotros-text13 {
    text-align: center;
  }
  .sobre-nosotros-hero4 {
    flex-direction: column;
  }
  .sobre-nosotros-container09 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .sobre-nosotros-text14 {
    text-align: center;
  }
  .sobre-nosotros-text15 {
    text-align: center;
  }
}
@media(max-width: 767px) {
  .sobre-nosotros-hero {
    align-items: center;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .sobre-nosotros-text {
    text-align: center;
  }
  .sobre-nosotros-text01 {
    max-width: 100%;
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .sobre-nosotros-features {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .sobre-nosotros-container02 {
    flex-direction: column;
  }
  .sobre-nosotros-text02 {
    text-align: center;
  }
  .sobre-nosotros-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .sobre-nosotros-image1 {
    width: 80%;
  }
  .sobre-nosotros-hero2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .sobre-nosotros-text11 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .sobre-nosotros-image2 {
    width: 80%;
  }
  .sobre-nosotros-hero3 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .sobre-nosotros-text13 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .sobre-nosotros-hero4 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .sobre-nosotros-text15 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .sobre-nosotros-hero {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .sobre-nosotros-text01 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .sobre-nosotros-button {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .sobre-nosotros-features {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .sobre-nosotros-image {
    width: 250px;
    height: 250px;
  }
  .sobre-nosotros-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .sobre-nosotros-container06 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .sobre-nosotros-text09 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .sobre-nosotros-hero2 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .sobre-nosotros-container07 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .sobre-nosotros-btn-group {
    flex-direction: column;
  }
  .sobre-nosotros-hero3 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .sobre-nosotros-container08 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .sobre-nosotros-hero4 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .sobre-nosotros-container09 {
    margin-bottom: var(--dl-space-space-unit);
  }
}
